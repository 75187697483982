import React, {
  useContext,
  createContext,
  useState,
  useEffect,
} from "react";
import { gql, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import moment from "moment";
import { alert } from "../components/common/Alert.js";
import { getDaysLeftInFreeTrial } from "../components/account/utils.js";
import { useIntercom } from "react-use-intercom";
import { useAccount } from "../hooks";
import { useFlags } from "launchdarkly-react-client-sdk";

const MS_IN_HOUR = 3600000;
const MS_IN_DAY = 86400000;
const MS_IN_MINUTE = 60000;

const LOGIN_USER = gql`
  mutation LoginUser($email: String, $password: String) {
    loginUser(email: $email, password: $password) {
      accessCsrf
      user {
        uuid
        firstName
        lastName
        isConfirmed
        organization {
          uuid
        }
        account {
          isSubscribed
          accountType
          freeTrialExpiresAt
        }
        createdAt
      }
    }
  }
`;

const CREATE_USER = gql`
  mutation CreateUser(
    $firstName: String
    $lastName: String
    $email: String
    $password: String
    $isAppointmentsBetaUser: Boolean
    $createdAt: DateTime
    $vlReferredCode: String
  ) {
    createUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      isAppointmentsBetaUser: $isAppointmentsBetaUser
      createdAt: $createdAt
      vlReferredCode: $vlReferredCode
    ) {
      user {
        uuid
        firstName
        lastName
      }
      accessCsrf
      isValidVlCode
    }
  }
`;

const PERSISTENT_LOGIN_USER = gql`
  mutation PersistentLoginUser {
    persistentLoginUser {
      accessCsrf
      user {
        uuid
        firstName
        email
        isConfirmed
        organization {
          uuid
        }
        account {
          isSubscribed
          accountType
          freeTrialExpiresAt
        }
        createdAt
      }
    }
  }
`;

const LOGOUT_USER = gql`
  mutation LogoutUser {
    logoutUser {
      ok
    }
  }
`;

const DELETE_USER = gql`
  mutation DeleteUser {
    deleteUser {
      ok
    }
  }
`;

const authContext = createContext();

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

function getAuthLocalStorage() {
  return (
    localStorage.getItem("is_user_authenticated") &&
    localStorage.getItem("accessCsrf") &&
    localStorage.getItem("auth_expiry") > moment.now()
  );
}

function removeLocalStorageKeyIfExists(key) {
  if (localStorage.getItem(key)) {
    localStorage.removeItem(key);
  }
}

function updateLocalStorageToCurrentVersion(currentVersion) {
  const storedVersion = localStorage.getItem("localstorage_version");
  if (storedVersion !== currentVersion) {
    if (!storedVersion) {
      console.log("Setting up localStorage for the first time");
    } else {
      console.log(
        `Updating localStorage from version ${storedVersion} to ${currentVersion}`,
      );
    }
    removeLocalStorageKeyIfExists("recording-uuid-appt");
    removeLocalStorageKeyIfExists("recording-uuid-summ");
    removeLocalStorageKeyIfExists(
      "is-date-input-setting-month-first",
    );

    localStorage.setItem("localstorage_version", currentVersion);
  }
}

// Provider hook that creates auth object and handles state
export function useProvideAuth() {
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(
    getAuthLocalStorage,
  );
  const [isReauthenticating, setIsReauthenticating] = useState(false);
  const currentVersion = process.env.REACT_APP_VERSION;
  const { shutdown } = useIntercom();
  const { earlyTokenExpiration } = useFlags();

  const removeLocalStorageAuth = () => {
    localStorage.removeItem("is_user_authenticated");
    localStorage.removeItem("accessCsrf");
    localStorage.removeItem("auth_expiry");
    setIsUserAuthenticated(false);
  };

  useEffect(() => {
    if (currentVersion) {
      updateLocalStorageToCurrentVersion(currentVersion);
    }
  }, [currentVersion]);

  const checkIsUserAuthenticated = () => {
    const isLocalStorageSet = getAuthLocalStorage();
    if (isLocalStorageSet) {
      return true;
    } else {
      removeLocalStorageAuth();
      return false;
    }
  };

  const checkIsTokenExpired = () => {
    if (moment.now() > localStorage.getItem("auth_expiry")) {
      return true;
    } else {
      return false;
    }
  };

  const checkIsTokenExpiringSoon = () => {
    const expiry = Number(localStorage.getItem("auth_expiry"));
    const now = moment.now();
    const two_hours_from_now = now + MS_IN_HOUR * 2;
    if (two_hours_from_now > expiry) {
      return true;
    } else {
      return false;
    }
  };

  const [loginUser, { error: loginError, loading: loginLoading }] =
    useMutation(LOGIN_USER);

  const [
    persistentLoginUser,
    { error: persistentLoginError, loading: persistentLoginLoading },
  ] = useMutation(PERSISTENT_LOGIN_USER);

  const [
    createUser,
    { error: createUserError, loading: createUserLoading },
  ] = useMutation(CREATE_USER);
  const [
    logoutUser,
    {
      error: logoutError,
      client: errorClientLogout,
      loading: logoutLoading,
    },
  ] = useMutation(LOGOUT_USER);
  const [
    deleteUser,
    {
      error: deleteError,
      client: errorClientDelete,
      loading: deleteLoading,
    },
  ] = useMutation(DELETE_USER);

  const navigate = useNavigate();
  const account = useAccount();

  const setAuthWithMutationResults = (mutationResults) => {
    const is_user_authenticated = mutationResults;
    const {
      accessCsrf,
      user: { uuid, firstName, organization },
    } = mutationResults;
    if (is_user_authenticated && accessCsrf) {
      localStorage.setItem("is_user_authenticated", true);
      localStorage.setItem("user_first_name", `${firstName}`);
      localStorage.setItem("user_uuid", uuid);
      localStorage.setItem("root_logged_in_user_uuid", uuid);
      localStorage.setItem("accessCsrf", accessCsrf);

      //Use an if/else rather than a ternary so that there is no FF
      //evaluation logic within the localStorage.setItem call
      if (earlyTokenExpiration) {
        localStorage.setItem(
          "auth_expiry",
          moment.now() + MS_IN_MINUTE,
        );
      } else {
        localStorage.setItem("auth_expiry", moment.now() + MS_IN_DAY);
      }

      //add this check to avoid a string 'null' value
      organization?.uuid &&
        localStorage.setItem("org_uuid", organization.uuid);
    }

    setIsUserAuthenticated(true);

    // if (!mutationResults?.user?.isConfirmed) {
    //   navigate("/registration_confirmation");
    // } REMOVE EMAIL CONFIRMATION
  };

  const signin = async (email, password) => {
    try {
      const response = await loginUser({
        variables: { email, password },
      });

      try {
        const { user } = response.data.loginUser;
        const { isConfirmed = null } = user;
        const userAccount = user?.account
          ? user.account
          : {
              isSubscribed: null,
              accountType: null,
            };

        const { isSubscribed, accountType } = userAccount;

        let isUserOnFreeTrial = false;
        const daysLeftInFreeTrial = getDaysLeftInFreeTrial(
          user?.account?.freeTrialExpiresAt,
        );
        if (daysLeftInFreeTrial > 0) {
          isUserOnFreeTrial = true;
        }

        account.updateAccountContext({
          accountType,
          isConfirmed,
          isSubscribed,
          isUserOnFreeTrial,
        });

        mixpanel.identify(user.uuid);

        mixpanel.people.set({
          $email: email,
          $first_name: user.firstName,
          $last_name: user.lastName,
        });

        mixpanel.register({
          platform: "web",
        });
        mixpanel.track("Sign In");
        localStorage.removeItem("already_reloaded"); //on sign in, remove the reload flag so kickEmOut will work again
      } catch (err) {
        console.log(err);
      }

      setAuthWithMutationResults(response.data.loginUser);
    } catch (errResponse) {
      const errors = errResponse?.graphQLErrors?.map((error) => {
        alert("error", error.message);
        if (
          error.message ===
          "Invalid authentication credentials supplied. Please try logging in again."
        ) {
          mixpanel.track("Sign In Error", {
            error: error.message,
            email: email,
          });
        }
      });
      // need to catch errors to prevent exception
      // might as well console log to debug if client has issue
      console.log(errors);
    }
  };

  const reauthenticateActiveUser = async () => {
    setIsReauthenticating(true);
    try {
      const response = await persistentLoginUser();

      try {
        const { user } = response.data.persistentLoginUser;
        const { isConfirmed = null } = user;
        const userAccount = user?.account
          ? user.account
          : {
              isSubscribed: null,
              accountType: null,
            };

        const { isSubscribed, accountType } = userAccount;

        let isUserOnFreeTrial = false;
        const daysLeftInFreeTrial = getDaysLeftInFreeTrial(
          user?.account?.freeTrialExpiresAt,
        );
        if (daysLeftInFreeTrial > 0) {
          isUserOnFreeTrial = true;
        }

        account.updateAccountContext({
          accountType,
          isConfirmed,
          isSubscribed,
          isUserOnFreeTrial,
        });
        mixpanel.track("Reauthenticated Active User");
      } catch (err) {
        console.log(err);
      }

      const mutationResults = response.data.persistentLoginUser;

      const is_user_authenticated = mutationResults;

      const {
        accessCsrf,
        user: { uuid, firstName },
      } = mutationResults;

      if (is_user_authenticated && accessCsrf) {
        localStorage.setItem("is_user_authenticated", true);
        localStorage.setItem("user_uuid", uuid);
        localStorage.setItem("accessCsrf", accessCsrf);

        //Reauthentication should always set the full JWT expiry time
        localStorage.setItem("auth_expiry", moment.now() + MS_IN_DAY);
      }

      setIsUserAuthenticated(true);
    } catch (errResponse) {
      const errors = errResponse?.graphQLErrors?.map((error) =>
        alert("error", error.message),
      );
      // need to catch errors to prevent exception
      // might as well console log to debug if client has issue
      console.log(errors);
    }

    setIsReauthenticating(false);
  };

  const register = (
    email,
    password,
    firstName,
    lastName,
    isAppointmentsBetaUser,
    vlReferredCode,
  ) => {
    return createUser({
      variables: {
        email,
        password,
        firstName,
        lastName,
        isAppointmentsBetaUser,
        createdAt: moment().format(),
        vlReferredCode,
      },
    })
      .then((response) => {
        setAuthWithMutationResults(response.data.createUser);
        //mixpanel event
        mixpanel.identify(response.data.createUser.user.uuid);

        mixpanel.people.set({
          $email: email,
          $first_name: firstName,
          $last_name: lastName,
        });

        mixpanel.register({
          platform: "web",
        });

        mixpanel.track("Sign Up");

        if (
          vlReferredCode &&
          response?.data?.createUser?.isValidVlCode
        ) {
          alert(
            "success",
            "Successfully registered with invite link. You have received free bonus Auto-Notes!",
          );
        } else if (
          vlReferredCode &&
          !response?.data?.createUser?.isValidVlCode
        ) {
          alert(
            "warning",
            "Invalid invite link. You have been registered, but you did not receive bonus Auto-Notes. Sorry :(",
          );
        }

        navigate("/tutorial");
      })
      .catch((response) => {
        console.log(response);
        const errors = response.graphQLErrors.map((error) =>
          alert("error", error.message),
        );
        // need to catch errors to prevent exception
        // might as well console log to debug if client has issue
        console.log(errors);
      });
  };

  const logout = () => {
    return logoutUser()
      .then(async () => {
        localStorage.removeItem("user_first_name");
        localStorage.removeItem("is_user_authenticated");
        localStorage.removeItem("accessCsrf");
        localStorage.removeItem("auth_expiry");
        localStorage.removeItem("org_uuid");
        localStorage.removeItem("currentTeam");
        localStorage.removeItem("user_uuid");
        localStorage.removeItem("already_reloaded"); //on log out, remove the reload flag so kickEmOut will work again

        mixpanel.reset();

        account.removeLocalStorageAccount();

        shutdown();
        setIsUserAuthenticated(false);

        await errorClientLogout.resetStore();
        let currentTimeInHours = new Date().getHours();

        const isDay =
          currentTimeInHours >= 3 && currentTimeInHours <= 16;

        const message = isDay
          ? "You've been signed out. We hope you have a wonderful day 🤙"
          : "You've been signed out. We hope you have a wonderful night 🤙";
        alert("info", message);
      })
      .catch(async (response) => {
        console.log(response);

        const errors = response.graphQLErrors.map((error) => {
          return error;
        });
        // need to catch errors to prevent exception
        // might as well console log to debug if client has issue
        console.log(errors);
      });
  };

  const deleteAccount = () => {
    return deleteUser()
      .then(async () => {
        localStorage.removeItem("is_user_authenticated");
        localStorage.removeItem("accessCsrf");
        localStorage.removeItem("auth_expiry");

        setIsUserAuthenticated(false);

        await errorClientDelete.resetStore();
        navigate("/");
      })
      .catch((response) => {
        console.log(response);
        const errors = response.graphQLErrors.map((error) =>
          alert("info", "Your account has been deleted!"),
        );
        // need to catch errors to prevent exception
        // might as well console log to debug if client has issue
        console.log(errors);
      });
  };

  return {
    userFirstName: localStorage.getItem("user_first_name"),
    orgUuid: localStorage.getItem("org_uuid"),
    userUuid: localStorage.getItem("user_uuid"),
    isUserAuthenticated,
    isReauthenticating,
    error:
      loginError ||
      persistentLoginError ||
      createUserError ||
      logoutError ||
      deleteError,
    loading:
      loginLoading ||
      createUserLoading ||
      logoutLoading ||
      deleteLoading,
    signin,
    reauthenticateActiveUser,
    register,
    logout,
    deleteAccount,
    checkIsUserAuthenticated,
    checkIsTokenExpired,
    checkIsTokenExpiringSoon,
  };
}

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function AuthProvider({ children }) {
  const auth = useProvideAuth();

  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}
